$black: #333;
$blue: #335687;
$lightblue: #6689BA;
$white: #FAFAFA;

$font-content: 'Lato', sans-serif;
$font-content-size: 15px;
$font-title: 'Montserrat', sans-serif;


$padding-xs: 5px;
$padding-s: 10px;
$padding-m: 15px;
$padding-l: 20px;
$padding-xl: 25px;
$padding-xxl: 30px;
$margin-xs: $padding-xs;
$margin-s: $padding-s;
$margin-m: $padding-m;
$margin-l: $padding-l;
$margin-xl: $padding-xl;
$margin-xxl: $padding-xxl;