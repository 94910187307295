@import '../../sass/mixins';
@import '../../sass/variables';
.table {
    border-collapse: collapse;
    text-align: center;
    width: 100%;
    thead{
        tr {
            box-shadow: 0 4px 3px lighten($black, 30);
            th {
                background-color: $lightblue;
                color: $white;
                margin-bottom: $margin-s;
                padding: $padding-s 0;
                &.hideable {
                    display: none;
                }
                @include tablet {
                    &.hideable {
                        display: table-cell;
                    }    
                }
            }
        }
    }
    tr:last-of-type td {
        border: none;
    }
}
