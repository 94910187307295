@import '../../sass/variables';
@import '../../sass/mixins';

.textarea-wrapper {
    background-color: transparent;
    border-bottom: 1px solid darken($white, 30);
    padding: $padding-s;
    position: relative;
    .textarea {
        @include remove-outline;
        background-color: transparent;
        border: none;
        color: $white;
        box-sizing: border-box;
        font-family: $font-content;
        font-size: 18px;
        width: 100%;
    }
}
