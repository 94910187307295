@import '../../sass/mixins';
@import '../../sass/variables';

.confirmacion-reclamo {
    color: $black;
    font-family: $font-content;
    &.white{
        color: white;
    }
    &.card {
        @include box-shadow(5px, $white);
        background-color: $blue;
        border-radius: 5px;
        padding: $padding-l;

    }
    li {
        list-style-type: none;
        margin-bottom: $margin-m;
        &:last-child {
                margin-bottom: 0;
            strong {
                display:block;       
            }
            img {
                max-width: 100%;
            }
        }
    }
}