@import '../../sass/variables';
@import '../../sass/mixins';

.reclamo-form {
    position: relative;
}
.form-item-description {
    color: $white;
    font-family: $font-content;
    width: 100%;
    a {
        color: #089de3
    }
}