@import '../../sass/mixins';
@import '../../sass/variables';

.view-detail {
    margin-top: $margin-s;
}
@include tablet {
    .view-detail {
        display: none;
    }
}

.reclamo-img-container {
    height: 100%;
    overflow: hidden;
    width: 100px;
    img {
        max-width: 100%;
    }
}
.modal-imagen {
    display: inline-block;
    height: auto!important;
    max-height: 95vh;
    max-width: none;
    left: 5vw;
    width: 90vw!important;
    img {
        max-width: 100%;
        width: 100%;
    }
}