@import '../../sass/variables';
@import '../../sass/mixins';

.form-modal {
    background: $black url('/img/reclamo-background.png');
    background-size: cover;
    height: 100vh;
    left: -100vw;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 20;
    .form-modal-header {
        @include flex-vertical-center;
        @include box-shadow(5px, $black);
        color: $white;
        font-family: $font-title;
        height: 55px;
        position: relative;
        width: 100%;
        .form-modal-header-title { 
            margin: 0;
        }
        .form-modal-header-close {
            @include remove-outline;
            background-color: transparent;
            border: none;
            color: $white;
            cursor: pointer;
            font-size: 22px;
            height: 40px;
            overflow: hidden;
            position: absolute;
            right: 10px;
            top: 10px;
            width: 40px;
            .close-modal-text {
                text-indent: 100vw;
                display: inline-block;
                position: absolute;
            }
        }
    }
    .form-modal-content {
        box-sizing: border-box;
        padding: $padding-xxl;
        width: 100%;
    }
}