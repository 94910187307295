@import '../../sass/variables';
@import '../../sass/mixins';

.main-header {
    @include flex-vertical-center;
    @include box-shadow(5px, $black);
    box-sizing: border-box;
    background-color: $blue;
    color: $white;
    height: 120px;
    .main-title{
        font-family: $font-title;
        font-size: 35px;
    }
}