@import '../../sass/variables';
@import '../../sass/mixins';

.input-label {
    position: relative;
    display: inline-block;
    width: 100%;
    .input {
        @include remove-outline;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid darken($white, 30);
        box-sizing: border-box;
        color: $white;
        font-family: $font-content;
        font-size: 18px;
        height: 45px;
        padding-left: $padding-s;
        width: 100%;
    }
}