@import '../../sass/variables';
@import '../../sass/mixins';

.content-main {
    background-color: $white;
}

.reclamo-table-container {
    padding: $padding-l;
}
.nuevo-reclamo {
    position: fixed;
    right: 15px;
    bottom: 15px;
    padding: $padding-m;
    z-index: 10;
}