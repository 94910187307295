@import '../../sass/variables';
@import '../../sass/mixins';

.button {
    @include remove-outline;
    border: none;
    border-radius: 4px;
    box-shadow: 0 0 2px $black;
    color: $black;
    font-family: $font-content;
    font-size: 13px;
    font-weight: 600;
    margin-right: $margin-s;
    overflow:hidden;
    padding: $padding-xs $padding-l;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.2s linear;
    &:active{
        box-shadow: inset 0 0 4px $black;
    }
    &:hover{
        background-color: darken(white, 15);
    }
    &.blue {
        background-color: $blue;
        color: white;
    }
    &.lightblue {
        background-color: lighten($lightblue, 10);
        color: $white;
        text-shadow: 0 0 10px $black;
        font-weight: 300;
    }
    &.black {
        background-color: $black;
        box-shadow: 0 0 2px $white;
        color: white;
        &:active{
            box-shadow: inset 0 0 4px $white;
        }
        &:hover {
            background-color: lighten($black, 10);
        }
    }

}